import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const customMarksData = createSlice({
  name: "marksstore",
  initialState: {
    customMarks: [],
  },
  reducers: {
    setCustomMarks: (state, action) => {
      action.payload.forEach((element) => {
        element.key = uuidv4();
      });
      state.customMarks = action.payload;
    },
  },
});

export const { setCustomMarks } = customMarksData.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.mainstore.value)`
export const getCustomMarks = (state) => state.marksstore.customMarks;

export default customMarksData.reducer;

/*
customMark Structure Example:
        {
            key: "9c894370-2ce3-4218-fs68-2176401e3f22",
            popup: false,
            description: "",
            type:"user-location",
            lat: 34.1811655,
            long: -118.4046149,
        },
*/
