import React, { useState } from "react";
import { Replay } from '@material-ui/icons';

import "../css/MainStyle.css";

import {
  setMapCoordinates,
  setEarthquakesAction,
  setEarthquakes
} from "../storeClasses/earthquakeData";

import {
  setCustomMarks,
} from "../storeClasses/customMarksData";

import { useSelector, useDispatch } from "react-redux";

export default function EarthquakesNearMe(props) {
  const dispatch = useDispatch();
  const [showEqNearMe, toggleShowEqNearMe] = useState(false);
  const [disabled, disableButton] = useState(false);


  function showPosition(position) {
    if (props.isDesktop) {
      dispatch(setMapCoordinates({lat: position.coords.latitude, long: position.coords.longitude,zoom:8}));
    }else{
      dispatch(setMapCoordinates({lat: position.coords.latitude, long: position.coords.longitude,zoom:7}));
    }
  
    const currentPosition = {lat :position.coords.latitude, long : position.coords.longitude};

    dispatch(
      setCustomMarks([{
        popup: false,
        description: "",
        type: "user-location",
        lat: currentPosition.lat,
        long: currentPosition.long,
      }])
    );

    var earthquakesList = [...props.earthquakes];
    for (let i = 0; i < earthquakesList.length; i++) {

      let distance = Math.sqrt(Math.pow((currentPosition.long - earthquakesList[i].long),2) + Math.pow((currentPosition.lat - earthquakesList[i].lat),2))
      if (distance > 1.5) {
        earthquakesList.splice(i, 1);
        i--;
      }
    } 
    dispatch(setEarthquakes(earthquakesList));
    toggleShowEqNearMe(true);
  }
  function geoError(error) {
    if (error.code == 1) {
      console.log('PERMISSION_DENIED');
    }
    // error.code can be:
    //   0: unknown error
    //   1: permission denied
    //   2: position unavailable (error response from location provider)
    //   3: timed out
  }

  function handleClick(e) {
    e.preventDefault();
    if (!disabled) {
      
      disableButton(true);
      setTimeout(() => disableButton(false), 2500);

      if (!showEqNearMe) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition,geoError);
        } else {
         console.log( "Geolocation is not supported by this browser.");
        }
      }else{
        dispatch(setEarthquakesAction());
        dispatch(
          setCustomMarks([])
        );
        toggleShowEqNearMe(false);
      }
    }
  }

  return (
    <div style={{margin: "0px" }}>
     <a style={{margin:"5px"}}  className="btn-grad" onClick={handleClick}>
       {showEqNearMe?<><Replay/> Show All<br/>Earthquakes </> : <>Show Earthquakes <br/> Near Me</>}
     </a>
    </div>
  );
}