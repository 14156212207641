import React from "react";
import { useDispatch } from "react-redux";
import { Marker, Popup, Circle } from "react-leaflet";

import * as markerService from "../../services/MapRadiusColor"
import {
    changeColor,
  } from "../../storeClasses/earthquakeData";


export default function EarthquakeMark(props) {
  const dispatch = useDispatch();
  return (
    <>
      <div>
        <Marker ico position={[props.earthquake.lat, props.earthquake.long]}>
          <Popup
            onOpen={() => {
              dispatch(changeColor(props.earthquake.id));
            }}
          >
            Mag {props.earthquake.mag} {props.earthquake.place}
            <br />
          </Popup>
        </Marker>
        <Circle
          center={[props.earthquake.lat, props.earthquake.long]}
          pathOptions={markerService.getColorByMag(props.earthquake.mag)}
          radius={markerService.getRadiusByMag(
            props.earthquake.mag,
            props.earthquake.lat
          )}
        />
      </div>
      {/* Duplicate to right */}
      <div>
        <Marker position={[props.earthquake.lat, props.earthquake.long + 360]}>
          <Popup
            onOpen={() => {
              dispatch(changeColor(props.earthquake.id));
            }}
          >
            Mag {props.earthquake.mag} {props.earthquake.place}
            <br />
          </Popup>
        </Marker>
        <Circle
          center={[props.earthquake.lat, props.earthquake.long + 360]}
          pathOptions={markerService.getColorByMag(props.earthquake.mag)}
          radius={markerService.getRadiusByMag(props.earthquake.mag, props.earthquake.lat)}
        />
      </div>
      {/* Duplicate to left */}
      <div>
        <Marker position={[props.earthquake.lat, props.earthquake.long - 360]}>
          <Popup
            onOpen={() => {
              dispatch(changeColor(props.earthquake.id));
            }}
          >
            Mag {props.earthquake.mag} {props.earthquake.place}
            <br />
          </Popup>
        </Marker>
        <Circle
          center={[props.earthquake.lat, props.earthquake.long - 360]}
          pathOptions={markerService.getColorByMag(props.earthquake.mag)}
          radius={markerService.getRadiusByMag(props.earthquake.mag, props.earthquake.lat)}
        />
      </div>
    </>
  );
}
