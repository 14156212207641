import L from 'leaflet';
import person from '../staticResources/customMapIcons/person.png';

const iconPerson = new L.Icon({
    iconUrl: person,
    iconRetinaUrl: person,
    iconAnchor: [20,46],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize:  [40, 46],
});

export { iconPerson };
