import React from "react";
import { useDispatch } from "react-redux";
import { Marker, Popup, Circle } from "react-leaflet";
import { iconPerson } from "../../services/CustomMapIcons";

import * as markerService from "../../services/MapRadiusColor";
import { changeColor } from "../../storeClasses/earthquakeData";

export default function UserLocationMark(props) {
  return (
    <>
      <div>
        <Marker
          icon={iconPerson}
          ico
          position={[props.customMark.lat, props.customMark.long]}
        >
          {props.customMark.popup ? (
            <Popup>
              {props.customMark.description}
              <br />
            </Popup>
          ) : (
            <></>
          )}
        </Marker>
      </div>
      {/* Duplicate to right */}
      <div>
        <Marker
          icon={iconPerson}
          position={[props.customMark.lat, props.customMark.long + 360]}
        >
          {props.customMark.popup ? (
            <Popup>
              {props.customMark.description}
              <br />
            </Popup>
          ) : (
            <></>
          )}
        </Marker>
      </div>
      {/* Duplicate to left */}
      <div>
        <Marker
          icon={iconPerson}
          position={[props.customMark.lat, props.customMark.long - 360]}
        >
          {props.customMark.popup ? (
            <Popup>
              {props.customMark.description}
              <br />
            </Popup>
          ) : (
            <></>
          )}
        </Marker>
      </div>
    </>
  );
}
